import Lenis from "@studio-freight/lenis";
import { annotate, annotationGroup } from "rough-notation";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(ScrollTrigger, SplitText);

// delay
const delay = (n) => {
  n = n || 2000;
  return new Promise((done) => {
    setTimeout(() => {
      done();
    }, n);
  });
};

// --------------------------------------------------
// DOM ready
// --------------------------------------------------
window.addEventListener("load", (e) => {
  initSmoothScrolling();
  initScrolltriggers();
  delay(500).then(() => document.body.classList.remove("loading"));
});

// --------------------------------------------------
// Initialise ScrollTriggers
// --------------------------------------------------
const initScrolltriggers = () => {
  // sections
  let sections = document.querySelectorAll("main > section");
  if (sections) {
    sections.forEach((el, index) => {
      ScrollTrigger.create({
        trigger: el,
        start: "top 80%",
        end: "bottom top",
        onEnter: () => {
          switch (index) {
            case 0:
              animationsOne(el);
              break;
            case 2:
              animationsTwo(el);
              break;
            case 3:
              animationsThree(el);
              break;
            case 4:
              animationsFour(el);
              break;
            case 5:
              animationsFive(el);
              break;
            default:
              break;
          }
        },
      });
    });
  }
  // quotes
  let quotes = document.querySelectorAll(".js-blockquotes > blockquote");
  if (quotes) {
    let defaults = { duration: 1, ease: "back.out", stagger: 0.15 };
    gsap.set(quotes, {
      transformOrigin: "center center",
      opacity: 0,
      scale: 0.6,
    });
    ScrollTrigger.batch(quotes, {
      onEnter: (elements, triggers) => {
        gsap.to(elements, {
          ...defaults,
          opacity: 1,
          scale: 1,
        });
      },
    });
  }
  // footer
  let element = document.querySelector("footer > svg:first-of-type");
  gsap.from(element, {
    transformOrigin: "center center",
    opacity: 0,
    ease: "back.out",
    scale: 0.6,
    scrollTrigger: {
      trigger: element,
      start: "top 80%",
      end: "bottom top",
    },
    onComplete: () => {
      let obj = document.querySelector("footer h2 span");
      let config = {
        type: "underline",
        color: "#E44000",
        iterations: 4,
        animationDuration: 600,
      };
      let highlight = annotate(obj, config);
      highlight.show();
    },
  });
};

// ---------------------------------------------------
// Initialise SmoothScrolling
// --------------------------------------------------
const initSmoothScrolling = () => {
  let lenis = new Lenis({ lerp: 0.2, smoothWheel: true });
  lenis.on("scroll", () => ScrollTrigger.update());
  const scrollFn = (time) => {
    lenis.raf(time);
    requestAnimationFrame(scrollFn);
  };
  requestAnimationFrame(scrollFn);
};

// --------------------------------------------------
// Animations
// --------------------------------------------------
const animationsOne = (el) => {
  let mySplitText = new SplitText("h1", { type: "words,chars" });
  let chars = mySplitText.chars;
  let tl = gsap.timeline({
    defaults: { ease: "back.out(2.5)" },
    onComplete: () => {
      mySplitText.revert();
    },
  });
  let logo = document.querySelector("header > svg");
  tl.from(logo, { transformOrigin: "center center", opacity: 0, scale: 0.6 });
  tl.from(chars, { opacity: 0, y: 24, stagger: 0.06 });
  tl.from(
    el.querySelector("p"),
    { duration: 1, opacity: 0, x: 50, ease: "power3.out" },
    "-=0.5"
  );
  tl.add(() => {
    let obj = el.querySelectorAll("p > span");
    if (obj) {
      let animObj = [];
      let config = {
        type: "highlight",
        color: "#eeeeee",
        animationDuration: 600,
      };
      obj.forEach((el) => animObj.push(annotate(el, config)));
      let highlight = annotationGroup(animObj);
      highlight.show();
    }
  }, "-=0.75");
};
const animationsTwo = (el) => {
  let obj = el.querySelector("blockquote > span");
  let config = {
    type: "highlight",
    color: "#000000",
    animationDuration: 2000,
    multiline: true,
  };
  let highlight = annotate(obj, config);
  highlight.show();
};
const animationsThree = (el) => {
  let obj = el.querySelector("h2 > span");
  let config = {
    type: "underline",
    color: "#E44000",
    iterations: 4,
    animationDuration: 600,
  };
  let highlight = annotate(obj, config);
  highlight.show();
  let columns = el.querySelectorAll(".js-column");
  let tl = gsap.timeline({ delay: 0.5, defaults: { ease: "power3.out" } });
  tl.from(columns[0], { x: -50, opacity: 0 });
  tl.from(columns[1], { x: 50, opacity: 0 }, "<");
};
const animationsFour = (el) => {
  let obj = el.querySelector("h2 > span");
  let config = {
    type: "underline",
    color: "#E44000",
    iterations: 4,
    animationDuration: 600,
  };
  let highlight = annotate(obj, config);
  highlight.show();
};
